import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SUB_EDITION_ENTITY_LIST_SEARCH_FRAGMENT = `
  fragment subEditionEntityListSearchFragment on SubEdition {
    id
    uid
    __typename
    schemaCode
    name
    code
    archived
    startTimestamp
    endTimestamp
    logoFileResource {
      ...fileResourceBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
    score: _score
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
